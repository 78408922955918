import { combineReducers } from "redux";
import { fetchNetworkId, fetchWeb3Data } from "./web3.reducer";

const rootReducer = combineReducers({

  fetchNetworkId,

  fetchWeb3Data,
});

export default rootReducer;
